import type { FC, ReactNode, PropsWithChildren } from 'react';

import { Title } from '@web-solutions/core/ui-elements';

import { TerminateFooter } from '../footer';

import classes from './style.module.scss';

interface TerminateTemplateProps extends PropsWithChildren {
  title: ReactNode,
  text: ReactNode,
  logo: ReactNode,
}

export const TerminateTemplate: FC<TerminateTemplateProps> = ({ title, text, logo, children }) => (
  <div className={classes.wrap}>
    <div className={classes.top}>
      {logo}
    </div>
    <div className={classes.texts}>
      <Title level='h2' className={classes.texts_title}>{title}</Title>
      <p className={classes.texts_text}>{text}</p>
    </div>
    <div className={classes.bottom}>{children}</div>
    <TerminateFooter />
  </div>
)
