import type { FC, BaseSyntheticEvent } from 'react'
import { useState } from 'react'
import classNames from 'classnames'

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { t } from '@web-solutions/manage-subscription/src/localization';

import { useNavigateManage } from '@web-solutions/manage-subscription/src/manage/hooks/use-navigate-next-screen';

import { baseTKey, ANALYTICS } from '../../contants'

import { StarIcon } from './icons'

import classes from './style.module.scss'

export const Form: FC = () => {
  const [activeStar, setActiveState] = useState(-1);
  const [reviewText, setReviewText] = useState('')

  const { navigateNextScreen } = useNavigateManage();

  const isContinueDisabled = activeStar < 0 && !reviewText.trim()

  const handleStarClick = (id: number) => () => {
    setActiveState((prev) => prev === id ? -1 : id)
  }

  const handleTextareaChange = (e: BaseSyntheticEvent) => setReviewText(e.target.value.replace(/\s+/g, ' '));

  const handleContinueClick = () => {
    if (!isContinueDisabled) {
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT, { stars: activeStar + 1, reviewText: reviewText.trim() });

      navigateNextScreen();
    }
  }

  const handleSkipClick = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SKIP);

    navigateNextScreen();
  }

  return (
    <div className={classes.formWrapper}>
      <div className={classes.fieldsWrapper}>
        <div className={classes.starsWrapper} >
          {new Array(5).fill(0).map((_, index) => (
            <button
              key={index}
              onClick={handleStarClick(index)}
            >
              <StarIcon
                key={index}
                className={classNames(classes.starIcon, { [classes.active]: index <= activeStar })}
              />
            </button>
          ))}
        </div>
        <textarea
          autoFocus
          className={classes.textarea}
          value={reviewText}
          onChange={handleTextareaChange}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <Button
          disabled={isContinueDisabled}
          className={classes.submitButton}
          titleClassName={classes.buttonTitle}
          title={t(`${baseTKey}.continue_btn`)}
          onClick={handleContinueClick}
        />
        <LinkButton
          className={classes.skipButton}
          onClick={handleSkipClick}
        >
          {t(`${baseTKey}.skip_btn`)}
        </LinkButton>
      </div>
    </div>
  )
}
