import { useEffect, useState } from 'react';
import queryString from 'query-string';
import Attribution from '@web-solutions/module-attribution';

export const useGetUrl = () => {
  const [url, setUrl] = useState('');
  const appLink = Attribution.getLinkToApp();
  const { platform } = queryString.parse(window.location.search);

  useEffect(() => {
    const fetchUrl = async () => {
      let newUrl = appLink;

      if (platform) {
        if (platform === 'web') {
          const parsedUrl = queryString.parseUrl(appLink);
          newUrl = queryString.stringifyUrl({
            url: `${parsedUrl.url}settings`,
            query: parsedUrl.query,
          });
        } else {
          const parsedQuery = queryString.parseUrl(appLink);
          parsedQuery.query.pathname = 'settings';
          newUrl = queryString.stringifyUrl(parsedQuery);
        }
      }

      setUrl(newUrl);
    };

    fetchUrl();
  }, [appLink, platform]);

  return url;
};
