import {
  getAuth,
  signInWithCustomToken,
  OAuthProvider,
  getRedirectResult,
  UserCredential,
  updateEmail
} from "firebase/auth";
import i18n from 'i18next';
// @ts-ignore
import md5 from 'nano-md5';

import DeviceProps from '@magnus/react-native-device-props';

import request from '@web-solutions/core/utils/network';
// @ts-ignore
import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

function generateAuthString(uuid: string, timestamp: number) {
  return md5(md5(uuid) + md5(timestamp) + md5(process.env.REACT_APP_AUTH_SECRET_KEY));
}

export function auth() {
  return DeviceProps.getIDFM()
    .then(idfm => Promise.all([
      authGeneral(idfm),
      authBilling(idfm),
    ]));
}

export function updateUserData(data: object) {
  return DeviceProps.getIDFM()
    .then(idfm => authGeneral(idfm, data));
}

function authGeneral(idfm: string, data: object = {}): Promise<any> {
  const timestamp = new Date().getTime();

  const body = {
    web_uuid: idfm,
    timestamp,
    auth_string: generateAuthString(idfm, timestamp),
    device_language: i18n.language?.split('-')?.[0],
    time_zone: -Math.floor(new Date().getTimezoneOffset() / 60),
    ...data,
  };

  return request.post('/auth/register-by-web', body);
};

function authBilling(idfm: string): Promise<{ access_token: string, token_type: string }> {
  return Promise.any([
    authBillingDeviceId(idfm),
    authBillingFirebase(idfm),
  ]);
}
function authBillingDeviceId(idfm: string): Promise<{ access_token: string, token_type: string }> {
  return billingApi.post('/auth/device-id', { device_id: idfm, });
}

const authBillingFirebase = (idfm: string): Promise<{ access_token: string, token_type: string }> =>
  billingApi.post<any, { custom_token: string }>('/auth/firebase/create-custom-token', { uid: idfm, })
    .then(({ custom_token }: { custom_token: string }) => signInWithCustomToken(getAuth(), custom_token))
    .then((userCred: UserCredential) => userCred.user.getIdToken())
    .then((token: string) => billingApi.post('/auth/firebase', { token, }));

export const checkRedirectResult = () => {
  return getRedirectResult(getAuth())
    .then((result) => {
      if (result) {
        const credential = OAuthProvider.credentialFromResult(result)
        if (credential) {
          return result.user;
        }
      }
    })
    .catch((error) => {
      console.warn(error);
      return error;
    });
}

export const updateUserEmail = (email: string) =>
  DeviceProps.getIDFM()
    .then(authBillingFirebase)
    .then(() => updateEmail(getAuth().currentUser!, email));